import { rootWrapper } from "./src/themes/root-wrapper"
import "./src/styles/global.css"
// import loadAffirmScript from "./src/vendor/affirm/loadAffirmScript.js"
// import React from "react"
// import {AffirmProvider} from "./src/components/features/AffirmMembership/AffirmContext"

// fix for scroll to top of page when link is clicked (i.e. in footer)
export const onPreRouteUpdate = () => {
  document.body.scrollTop = 0
}

export const wrapPageElement = rootWrapper
// export const wrapRootElement = ({ element }) => (
//   <AffirmProvider>{element}</AffirmProvider>
// )

// const addMyScript = loadScriptFunction => {
//   const script = document.createElement("script")
//   script.src = loadScriptFunction()
//   // script.src = "./src/vendor/affirm/loadAffirmScript.js"
//   script.async = true
//   // document.body.appendChild(script)
// }

// export const onRenderBody = ({ pathname, setHeadComponents }) => {
//   if (pathname === "/join") {
//     setHeadComponents([
//       <script
//         key="affirm.js"
//         type="text/javascript"
//         src="scripts/affirm/affirm.js"
//       />,
//     ])
//   }
// }

// export const onRouteUpdate = ({ location }) => {
//   console.warn("[onRouteUpdate] location:", location)
//   const path = location?.pathname
//   console.warn("[onRouteUpdate] path:", path)

//   if (path === "/join") {
//     console.warn(
//       "[onRouteUpdate] in the '/join' block, baby! We on the 'join' page!"
//     )
    
//     addMyScript(loadAffirmScript)
//   } else if (path === "join") {
//     console.warn("Just kidding, now loading Affirm script...")
//   } else {
//     console.warn("Affirm script not loaded...")
//   }
// }


// const addScript = url => {
//   const script = document.createElement("script")
//   script.src = url
//   script.async = true
//   document.body.appendChild(script)
// }

// export const onClientEntry = () => {
//   window.onload = () => {
//     addScript("./static/scripts/affirm/affirm.js")
//     addScript("scripts/affirm/affirm.js")
//   }
// }
