const size = {
  xxs: "320px",
  xs: "480px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  xxl: "1500px",
}

const Theme = {
  content: {
    width: "85%",
    maxWidth: "860px",
    margin: "0 auto",
  },
  fonts: {
    family: {
      heading: "Exo, Roboto, sans-serif",
      subheading: "Exo, Roboto, sans-serif",
      body: "Roboto, sans-serif",
    },
    weight: {
      regular: "400",
      light: "300",
      bold: "700",
    },
    size: {
      heading: {
        sm: "2rem",
        md: "2.25rem",
        lg: "2.6667rem",
      },
      subheading: {
        sm: "1.25rem",
        md: "1.5rem",
        lg: "1.6667rem",
      },
      body: {
        sm: "1rem",
        md: "1.125rem",
        lg: "1.1667rem",
      },
    },
    // tracking = letter-spacing
    letterSpacing: {
      heading: "0.01rem", // tracking 10
      subheading: "0.028rem", // tracking 28
      body: "0.01rem", // tracking 10
    },
    // leading = line-height
    lineHeight: {
      heading: 1.6,
      subheading: "2rem", // leading 14
      // subheading: 1.6,
      body: "2rem",
      // body: 1.6,
    },
  },
  textAlign: {},
  colors: {
    blue: "hsl(195,100%,40%)",
    red: "hsl(353,100%,61%)",
    orange: "hsl(24,100%,56%)",
    yellowOrange: "hsl(44,100%,50%)",
    darkBlue: "hsl(220,48%,20%)",
    darkGray: "#333",
  },
  breakpoints: {
    mobile: "only screen and (max-width: 50rem)", // 800px
    tablet: "only screen and (max-width: 65rem)", // 1040px
    xxs: `(min-width: ${size.xxs})`,
    xs: `(min-width: ${size.xs})`,
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
    xl: `(min-width: ${size.xl})`,
    xxl: `(min-width: ${size.xxl})`,
  },
  spacings: {
    xxSmall: ".25rem",
    xSmall: ".5rem",
    small: "1rem",
    medium: "2rem",
    large: "3rem",
    xLarge: "4rem",
    xxLarge: "6rem",
  },
  animations: {
    button: "box-shadow 0.3s ease",
    link: "color 0.2s ease",
  },
  shadows: {
    shadow1: "0px 5px 20px rgba(30, 30, 31, 0.05)",
  },
}

export default Theme
