import { createGlobalStyle, ThemeProvider } from "styled-components"
import Theme from "./theme"
import React from "react"

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  :root {
    font-size: 16px;
  }

  body, html {
    font-size: 1rem;
    line-height: 1.6;

    @media ${props => props.theme.breakpoints.md} {
      font-size: ${props => props.theme.fonts.size.body.md}
    }
    @media ${props => props.theme.breakpoints.lg} {
      font-size: ${props => props.theme.fonts.size.body.lg};
    }
    color: ${props => props.theme.colors.darkBlue};
    font-family: ${props => props.theme.fonts.family.body};
    max-width: 100%;
    height: 100%;
    position: relative;
    overflow-x: hidden;

  }

  img {
    max-width: 100%;
  }

  h1,h2,h3,h4,h5,h6 {
    line-height: ${props => props.theme.fonts.lineHeight.heading}; /* 1.6 */
  }

  p, ul, li {
    @media ${props => props.theme.breakpoints.md} {
      font-size: ${props => props.theme.fonts.size.body.md}
    }
    @media ${props => props.theme.breakpoints.lg} {
      font-size: ${props => props.theme.fonts.size.body.lg};
    }

    font-family: ${props => props.theme.fonts.family.body};
    font-weight: ${props => props.theme.fonts.weight.regular};
    @media ${props => props.theme.breakpoints.md} {
      font-weight: ${props => props.theme.fonts.weight.light}
    }    
    letter-spacing: ${props => props.theme.fonts.letterSpacing.body};
    line-height: ${props => props.theme.fonts.lineHeight.body}; 
  }

  h1 {
    font-family: ${props => props.theme.fonts.family.heading};
    font-size: ${props => props.theme.fonts.size.heading.sm};

    @media ${props => props.theme.breakpoints.md} {
      font-size: ${props => props.theme.fonts.size.heading.md};
    }
    @media ${props => props.theme.breakpoints.lg} {
      font-size: ${props => props.theme.fonts.size.heading.lg};
    }
    font-weight: ${props => props.theme.fonts.weight.bold};
    color: ${props => props.theme.colors.darkBlue};
    letter-spacing: ${props => props.theme.fonts.letterSpacing.heading};
    line-height: ${props => props.theme.fonts.lineHeight.heading};
    text-align: ${props => (props.textAlign ? props.textAlign : "left")};
    margin: ${props => (props.margin ? props.margin : 0)};
    
  }

  h2 {
    font-family: ${props => props.theme.fonts.family.subheading};
    font-size: ${props => props.theme.fonts.size.subheading.sm};

    @media ${props => props.theme.breakpoints.md} {
      font-size: ${props => props.theme.fonts.size.subheading.md};
    }
    @media ${props => props.theme.breakpoints.lg} {
      font-size: ${props => props.theme.fonts.size.subheading.lg};
    }
    font-weight: ${props => props.theme.fonts.weight.bold};
    color: ${props => props.theme.colors.darkBlue};
    letter-spacing: ${props => props.theme.fonts.letterSpacing.subheading};
    line-height: ${props => props.theme.fonts.lineHeight.heading};
    text-align: ${props => (props.textAlign ? props.textAlign : "left")};
    margin: ${props => (props.margin ? props.margin : 0)};
  }

  h3 {
    font-family: ${props => props.theme.fonts.family.subheading};
    font-size: ${props => props.theme.fonts.size.body.sm};

    @media ${props => props.theme.breakpoints.md} {
      font-size: ${props => props.theme.fonts.size.body.md};
    }
    @media ${props => props.theme.breakpoints.lg} {
      font-size: ${props => props.theme.fonts.size.body.lg};
    }
    font-weight: ${props => props.theme.fonts.weight.bold};
    color: ${props => props.theme.colors.darkBlue};
    letter-spacing: ${props => props.theme.fonts.letterSpacing.subheading};
    line-height: ${props => props.theme.fonts.lineHeight.heading};
    text-align: ${props => (props.textAlign ? props.textAlign : "left")};
    margin: ${props => (props.margin ? props.margin : 0)};
  }

  a {
    color: ${props => props.theme.colors.blue};
  }

  /* For rendering lightbox popup on homepage and allow mobile users to pinch & zoom and expand image beyond wrapper dimensions
    Without overflow: inherit, the expanded image would be hidden within the wrapper
    Default in .react-transform-wrapper is overflow: hidden... see https://github.com/prc5/react-zoom-pan-pinch/issues/186
    Even though in Simple-React-Lightbox there are style overrides for .react-transform-wrapper in simple-react-lightbox/src/SRL/styles/SRLElementContainerStyles.js around line 120, "react-zoom-pan-pinch library styles overrides", they aren't working
   */
  .react-transform-wrapper {
    overflow: inherit;
  }

  details summary > * { 
    display: inline;
  }

  details summary::marker {
    font-size: 30px;
  }

  /* For note above CourtReserve calendar iframe on Calendar page
    '(Scroll on the right of the inset frame to view all upcoming options.)'
    - To encourage vertical scrolling
  */
  .iframe-scroll-msg {
    margin: 1rem 0;
  }

  // Google form on Fitness Benefits page
  #fitness-benefit-form {
    iframe {
      width: 100%;
    }
  }
`

// wrapping entire application in ThemeProvide
export const rootWrapper = ({ element }) => (
  <ThemeProvider theme={Theme}>
    <GlobalStyle />
    {element}
  </ThemeProvider>
)
